import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

import machine1 from '../../../assets/images/creel2.jpg'

const CreelsDetails = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <section className='header-section'>
                <div className=' container-box'>
                    <div className="row">
                        <div className="col-lg-6 col-md-8">
                            <div className='header-heading'>
                                <h2>Creels Details</h2>
                                <p>
                                    <Link to='/' aria-label="Home Page Link">Home</Link>
                                    <FontAwesomeIcon
                                        icon={faArrowRightLong}
                                        className="px-3"
                                    ></FontAwesomeIcon>
                                    CreelsDetails
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5 bg-light'>
                <div className="container-box pb-5 pt-4">
                    <div className="row justify-content-between">
                        <div className="col-md-6">
                            <h2 className='text-dark-blue h2 fw-bold'>Reliable Power Systems you can depend on</h2>
                            <p className='paragraph mt-4'>
                                As a leader in the large generator set market, Caterpillar offers the industry’s widest range of electric power systems ranging from 6 kW to 12,560 kW, with more than 500,000 Cat generator sets operating worldwide. Behind every piece of Cat electric power equipment comes:
                            </p>

                            <ul className='ps-0'>
                                <li className='paragraph'>
                                    <strong>A focus on quality –</strong> At our world-class validation and testing facilities, every system is designed, built, inspected, and tested to Caterpillar standards for reliability and durability.
                                </li>
                                <li className='paragraph mt-2'>
                                    <strong>Proven performance –</strong> Designed with durability in mind, Cat electric power equipment provides peace of mind with dependable power tailored to your operation.
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-6 ps-md-5 px-0">
                            <img src={machine1} alt="" className='w-100' />
                        </div>

                        <div className="col-12 my-4">
                            <div className="row text-center">
                                <div className="col-md-4 col-xl-3">
                                    <div className='bg-white p-3 shadow py-4 machine-counts'>
                                        <h5 className='text-dark-blue fw-bold'>Operation Temperature</h5>
                                        <p className='mb-0' style={{ letterSpacing: '2px' }}> -45°F To +185°F </p>
                                    </div>

                                </div>

                                <div className="col-md-4 col-xl-3">
                                    <div className='bg-white p-3 shadow py-4 machine-counts'>
                                        <h5 className='text-dark-blue fw-bold'>Dimensions</h5>
                                        <p className='mb-0' style={{ letterSpacing: '2px' }}> 68.5* X 40.5* X 1.57* </p>
                                    </div>

                                </div>

                                <div className="col-md-4 col-xl-3">
                                    <div className='bg-white p-3 shadow py-4 machine-counts'>
                                        <h5 className='text-dark-blue fw-bold'>Invert Power</h5>
                                        <p className='mb-0' style={{ letterSpacing: '2px' }}> 3.8kw / 7.6kw - 97.75% </p>
                                    </div>
                                </div>

                                <div className="col-md-4 col-xl-3">
                                    <div className='bg-white p-3 shadow py-4 machine-counts'>
                                        <h5 className='text-dark-blue fw-bold'>Frame Type</h5>
                                        <p className='mb-0' style={{ letterSpacing: '2px' }}> Aluminum </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-4">
                            <p className='paragraph'>
                                See for yourself why your Cat generator won’t let you down in the field. Every Cat generator set undergoes a rigorous series of factory tests. Over twenty test cells are dedicated to special testing, with the capacity to test open, canopied, and containerized generator sets at both inductive and resistive loads.
                            </p>
                            <p className='paragraph'>
                                Using up to 12 MW resistive capacity, a 3 MVAr inductive capacity, and a 750 kVAr capacitive capacity, we can test low- and high-voltage generator sets and multiple units synchronized together, simulating site conditions and installations.
                            </p>
                        </div>

                        <div className="col-12 mt-4">
                            <h2 className='fw-bold mb-5 text-dark-blue'>Features of the Machinery Used in the Equipment Manufacturing Industry</h2>

                            <ul className='machine-features ps-5'>
                                <li className='paragraph mb-4'>
                                    The machinery industry has always been quite a big one. Initially, it focused on various automotive, transport, aerospace, and process industries sectors.
                                </li>

                                <li className='paragraph mb-4'>
                                    That made the machinery industry bigger and more developed over time. However, its impact is much broader today.
                                </li>

                                <li className='paragraph mb-4'>
                                    As the technology gets better, companies using machinery are looking to introduce personalized builds and designs that flawlessly execute their tasks and fit their needs.
                                </li>

                                <li className='paragraph mb-4'>
                                    Although all features are vital, efficiency, affordability, performance, and size play a crucial role in building better, improved machinery solutions.
                                </li>

                                <li className='paragraph'>
                                    Another critical feature of machinery used in the equipment manufacturing industry is the development of innovative solutions based on deep learning.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5'>
                <div className="container-box py-5">
                    <div className="mb-5">
                        <h2 className='text-dark-blue h2'>More Machines</h2>
                        <p className='paragraph'>
                            The Mccoy equipment product line, consisting of more than 300 machines, sets the standard for our industry. We plan to help you meet your needs with our equipment, with our distribution and product support system, and the continual introduction and updating of products.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className='machine-card mb-md-0 mb-4'>
                                <img src="https://s7d2.scene7.com/is/image/Caterpillar/CM20160520-36727-46538" className='w-100' style={{ height: '200px', objectFit: 'cover' }} alt="" />
                                <div className='py-3 ps-3 border'>
                                    <h5 className='mb-0 fw-bold'>Electric Power</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className='machine-card mb-md-0 mb-4'>
                                <img src="https://s7d2.scene7.com/is/image/Caterpillar/CM20210122-2b169-07986" className='w-100' style={{ height: '200px', objectFit: 'cover' }} alt="" />
                                <div className='py-3 ps-3 border'>
                                    <h5 className='mb-0 fw-bold'>Industrial Power</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className='machine-card mb-md-0 mb-4'>
                                <img src="https://s7d2.scene7.com/is/image/Caterpillar/CM20160520-36727-46538" className='w-100' style={{ height: '200px', objectFit: 'cover' }} alt="" />
                                <div className='py-3 ps-3 border'>
                                    <h5 className='mb-0 fw-bold'>Electric Power</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreelsDetails
