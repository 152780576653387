import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import Spinner from "../HomePage/Spinner";

const OurTeam = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  document.title = "Our Team | McCoy Machinery Company, Inc.";

  const [isLoading, setLoading] = useState(true);
  const [teamIntro, setTeamIntro] = useState([]);
  const [team, setTeam] = useState([]);
  const [ctaSection, setCtaSection] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/team"
        );
        setTeamIntro(data.team_introsection);
        setTeam(data.team);
        setCtaSection(data.cta_section);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <section className="header-section">
        <div className=" container-box">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="header-heading">
                <h2>Our Team</h2>
                <p>
                  <Link to="/" aria-label="Home Page Link">
                    Home
                  </Link>
                  <FontAwesomeIcon
                    icon={faArrowRightLong}
                    className="px-3"
                  ></FontAwesomeIcon>
                  Our Team
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-md-3">
        <div className="container-box py-5">
          {teamIntro.map((item, index) => {
            return (
              <div className="row" key={index}>
                <div className="col-12">
                  <h2 className="text-dark-blue h2 mb-3">{item.team_title}</h2>
                  <h5 className="h5">{item.team_subtitle}</h5>

                  <p className="paragraph">{item.team_overview}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="bg-dark-blue py-md-5 py-4">
          <div className="container-box pb-5 row">
            {team.map((item, index) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="team-card bg-white">
                    <img src={`https://mccoy-usa.com/admin/assets/front/img/members/${item.image}`} alt="teamPhoto" />

                    <div>
                      <h5 className="text-dark-blue fw-bold text-uppercase">
                        {item.name}
                      </h5>
                      <h6>{item.rank}</h6>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="team-card bg-white">
                <img src={person2} alt="teamPhoto" />
                <div>
                  <h5 className="text-dark-blue fw-bold text-uppercase">
                    Robin Belk{" "}
                  </h5>
                  <h6>V.P. Operations & Customer Service</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="team-card bg-white">
                <img src={noImage} alt="teamPhoto" />

                <div>
                  <h5 className="text-dark-blue fw-bold">CANDICE TAYLOR</h5>
                  <h6>Accounting Manager</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="team-card bg-white">
                <img
                  src={noImage}
                  style={{ objectPosition: "0 23%" }}
                  alt="teamPhoto"
                />

                <div>
                  <h5 className="text-dark-blue fw-bold">SHAIL PARIKH</h5>
                  <h6>VP Electrical Engineering</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="team-card bg-white">
                <img src={person3} alt="teamPhoto" />

                <div>
                  <h5 className="text-dark-blue fw-bold">DAVIS AHLSTROM</h5>
                  <h6>Mechanical Engineering Manager</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="team-card bg-white">
                <img src={person5} alt="teamPhoto" />

                <div>
                  <h5 className="text-dark-blue fw-bold">DEAN KILL</h5>
                  <h6 className="px-3">Electrical Engineering Manager</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="team-card bg-white">
                <img src={noImage} alt="teamPhoto" />

                <div>
                  <h5 className="text-dark-blue fw-bold">JAMES HARPER</h5>
                  <h6>Logistics & Purchasing Manager</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="team-card bg-white">
                <img
                  src={person6}
                  style={{ objectPosition: "0 23%" }}
                  alt="teamPhoto"
                />

                <div>
                  <h5 className="text-dark-blue fw-bold">CHANCE AHLSTROM</h5>
                  <h6>Lead Technical Service Representative</h6>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container-box py-md-5 ">
          {ctaSection.map((item, index) => {
            return (
              <div className="row align-items-center" key={index}>
                <div className="col-md-8 pe-md-5">
                  <h2 className="h2 text-dark-blue">{item.teamcta_title} </h2>
                  <h5 className="h5 py-2">{item.teamcta_subtitle}</h5>

                  <p className="paragraph text-justify">
                  <div
                              dangerouslySetInnerHTML={{
                                __html: item.teamcta_overview,
                              }}
                            />
                  </p>
                  <Link
                    to="/contact"
                    className="carousel-content-btn mt-2 d-inline-block"
                  >
                    {item.teamcta_btntext}
                  </Link>
                </div>

                <div className="col-md-4 text-end d-md-block d-none">
                  <img src={`https://mccoy-usa.com/admin/assets/front/img/${item.teamcta_img}`} alt="" className="w-100" />
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default OurTeam;
