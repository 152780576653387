import React, { useEffect } from "react";

// logo2 image
import logo2 from "../../assets/images/logo2.png";
import logo from "../../assets/images/logo3.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

export const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <footer className="bg-light border-top">
      <div className="footer-content pt-5 pb-4">
        <div className="row justify-content-sm-between justify-content-center">
          <div className="col-md-3 col-11 mb-md-0 mb-4">
            <Link to="/" aria-label="Home Page Link">
              <img src={logo} alt="" className="w-100" />
            </Link>
          </div>
          <div className="col-lg-2 col-md-3 col-11 mt-md-0 mt-4 px-0">
            <h5>Postal Address:</h5>

            <p className="mb-1">McCoy Machinery Co., Inc</p>
            <p className="mb-1">P.O. Box 967</p>
            <p className="mb-md-4">Monroe, NC 28111-0967 USA</p>
          </div>
          <div className="col-lg-2 col-md-3 col-11 mt-md-0 mt-4 px-0 ">
            <h5>Physical Address:</h5>

            <p className="mb-1">McCoy Machinery Co., Inc </p>
            <p className="mb-1">1101 Curtis Street</p>
            <p className="mb-md-4">Monroe, NC 28112 USA</p>
          </div>
          <div className="col-md-3 col-11 mt-md-0 mt-4 px-0">
            <h5>
              <Link
                to="/contact"
                className="text-decoration-none text-dark-blue fw-bold"
              >
                {" "}
                Contact Us
              </Link>
            </h5>
            <p className="mb-2">
              <Link
                to="mailto:info@mccoy-usa.com"
                className="text-decoration-none"
                style={{ color: "#485f7a" }}
              >
                {" "}
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="header-icon me-2"
                ></FontAwesomeIcon>
                info@mccoy-usa.com{" "}
              </Link>
            </p>
            <Link
              to="tel:+1 (704) 289-5413"
              className="text-decoration-none"
              style={{ color: "#485f7a" }}
            >
              <FontAwesomeIcon
                icon={faPhone}
                className="header-icon me-2"
              ></FontAwesomeIcon>
              +1 (704) 289-5413{" "}
            </Link>
          </div>
        </div>
      </div>
      <div className="d-md-flex design-footer justify-content-between px-md-5 px-3 py-4">
        <div className="text-white pb-md-0 pb-3">
          &copy; 2024 McCoy Machinery Company, Inc. | All Rights Reserved
        </div>
        <div className="text-white">
          Design and Developed by{" "}
          <a
            href="https://www.zithas.com/"
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            Zithas Technologies
          </a>
        </div>
      </div>
    </footer>
  );
};
