import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

import machine1 from "../../../assets/images/driven-system.jpg";
import Spinner from "../../HomePage/Spinner";
import axios from "axios";

const DrivenSystem = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  document.title = "Driven System | McCoy Machinery Company, Inc.";

  const [isLoading, setLoading] = useState(true);
  const [drivenSystems, setDrivenSystems] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/composites"
        );
        setDrivenSystems(data.driven_systems);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <section className="header-section">
        <div className=" container-box">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="header-heading">
                <h2>Driven System</h2>
                <p>
                  <Link to="/" aria-label="Home Page Link">
                    Home
                  </Link>
                  <FontAwesomeIcon
                    icon={faArrowRightLong}
                    className="px-3"
                  ></FontAwesomeIcon>
                  Driven System
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container-box pb-5 pt-4">
          {drivenSystems.map((machine, i) => {
            return (
              <div
                className="row justify-content-between align-items-md-center"
                key={i}
              >
                <div className="col-md-6">
                  <h2 className="text-dark-blue h2 fw-bold">{machine.title}</h2>
                  <p className="paragraph mt-4">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: machine.overview,
                      }}
                    />
                  </p>
                </div>

                <div className="col-md-6 ps-md-5 px-0">
                  <img
                    src={`https://mccoy-usa.com/admin/assets/front/img/courses/${machine.textile_image}`}
                    alt=""
                    className="w-100"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default DrivenSystem;
