import React from "react";
import "../../assets/css/default/Modal.css";

const Spinner = () => {
  return (
    <div>
      <section className="spinner" style={{ textAlign: "center" }}>
        <div className="spinner-border text-center" role="status" style={{ color: "var(--clr-body-heading)", marginTop: "25%" }}>
          <span className="visually-hidden">Loading...</span>
        </div>
      </section>

    </div>
    // <div className="spinner-loader">
    //   <div className="bd-space-spinner">
    //     <div className="reverse-spinner"></div>
    //   </div>

    //   <div>
    //     <div className="hm-spinner"></div>
    //   </div>
    // </div>
  );
};

export default Spinner;
