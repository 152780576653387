import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAngleDown,
  faArrowUp,
  faBars,
  faPhone,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

// css
import "../../assets/css/Home.css";

const InnerLinks = ({ setMobileMenu, linkData, name }) => {
  const [innerLinks, setInnerLinks] = useState(false);

  return (
    <li>
      <Link
        to={linkData.link}
        onClick={() => setInnerLinks(!innerLinks)}
        className="d-flex justify-content-between align-items-center py-0"
        style={{ cursor: "pointer" }}
      >
        {name}
        <button className="ps-4 py-2 bg-transparent text-end border-0 text-white">
          <i className={`bi bi-caret-${innerLinks ? "up" : "down"}`}></i>
        </button>
      </Link>
      <ul
        className={`mobile-inner-links d-block ${
          innerLinks ? "d-block" : "d-none"
        }`}
      >
        {linkData.map((sublink, i) => {
          return (
            <li key={i}>
              <NavLink
                onClick={() => setMobileMenu(false)}
                to={sublink.link}
                className="d-flex align-items-center fs-6 text-light"
              >
                <i className="bi bi-chevron-right me-1 text-orange"></i>
                {sublink.label}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

const Navbar = () => {
  const [searchBar, setSearchBar] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const [fixedNav, setFixedNav] = useState(false);
  const [upArrow, setUpArrow] = useState(false);
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  window.addEventListener("scroll", () => {
    // if (window.scrollY > 100) {
    //   setStickyNavbar(true);
    // } else {
    //   setStickyNavbar(false);
    // }
    if (window.scrollY > 90) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }

    if (window.scrollY > 220) {
      setUpArrow(true);
    } else {
      setUpArrow(false);
    }
  });

  //   window.addEventListener("scroll", () => {
  //     if (window.scrollY > 130) {
  //       setFixedNav(true);
  //     } else {
  //       setFixedNav(false);
  //     }

  //     if (window.scrollY > 220) {
  //       setUpArrow(true);
  //     } else {
  //       setUpArrow(false);
  //     }
  //   });

  const aboutLinks = [
    {
      label: "About Mccoy",
      link: "about",
    },
    {
      label: "Our Team",
      link: "/about/our-team",
    },
  ];
  const textileLinks = [
    {
      label: "warp preparation",
      link: "warp-preparation",
    },
    {
      label: "creels",
      link: "creels",
    },
    {
      label: "Tension Devices",
      link: "tension-devices",
    },
    {
      label: "Ancillary",
      link: "ancillarys",
    },
    {
      label: "Parts and Accessories",
      link: "parts-accessories",
    },
    {
      label: "Service",
      link: "service",
    },
  ];

  const compositeLinks = [
    {
      label: "unrolling creels",
      link: "/unrolling-creels",
    },
    {
      label: "Driven systems",
      link: "/composites/driven-systems",
    },
    {
      label: "custom solutions",
      link: "/composites/custom-solutions",
    },
    {
      label: "parts & accessories",
      link: "/parts-accessories",
    },
    {
      label: "Service",
      link: "/service",
    },
  ];

  const contactLinks = [
    {
      label: "Contact Us",
      link: "contact",
    },
    {
      label: "Sales",
      link: "contact/request-a-quotes",
    },
    {
      label: "Repair / service",
      link: "service",
    },
    {
      label: "Parts",
      link: "parts-accessories",
    },
  ];

  return (
    <header>
      <div className={`header ${stickyNavbar ? "d-none" : ""}`}>
        <div className="header-content">
          <div className="desktop-contact-info">
            <FontAwesomeIcon
              icon={faPhone}
              className="header-icon me-2"
            ></FontAwesomeIcon>
            <Link
              to="tel:+1 (704) 289-5413"
              className="text-white text-decoration-none"
            >
              {" "}
              +1 (704) 289-5413{" "}
            </Link>
          </div>
          <div className="desktop-contact-info">
            <FontAwesomeIcon
              icon={faEnvelope}
              className="header-icon me-2"
            ></FontAwesomeIcon>
            <Link
              to="mailto:info@mccoy-usa.com"
              className="text-white text-decoration-none"
            >
              {" "}
              info@mccoy-usa.com{" "}
            </Link>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faFacebook}
              className="social-icon"
            ></FontAwesomeIcon>
            <FontAwesomeIcon
              icon={faInstagram}
              className="social-icon"
            ></FontAwesomeIcon>
            <FontAwesomeIcon
              icon={faTwitter}
              className="social-icon"
            ></FontAwesomeIcon>
            <FontAwesomeIcon
              icon={faLinkedin}
              className="social-icon"
            ></FontAwesomeIcon>
          </div>
        </div>
      </div>
      <nav className={``}>
        <div
          className={`container-box py-2 navibar ${
            stickyNavbar ? "white-navbar" : ""
          }`}
          id="rajveer-impex"
        >
          <div className="">
            <NavLink to="" className="logo">
              <img src={logo} className="w-90" loading="lazy" alt="logo" />
            </NavLink>
          </div>

          <div className="d-flex justify-content-end w-100">
            <ul className="navlinks">
              <li>
                <NavLink to="">Home</NavLink>
              </li>
              <li>
                <Link to="/about">
                  About <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                </Link>
                <ul className="inner-links">
                  <li>
                    <Link to="/about" onClick={() => setOpenNav(false)}>
                      About Mccoy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about/our-team"
                      onClick={() => setOpenNav(false)}
                    >
                      Our Team
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/warp-preparation">
                  textiles{" "}
                  <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                </Link>
                <ul className="inner-links">
                  <li>
                    <Link
                      to="/warp-preparation"
                      onClick={() => setOpenNav(false)}
                    >
                      warp preparation
                    </Link>
                  </li>
                  <li>
                    <Link to="/creels" onClick={() => setOpenNav(false)}>
                      creels
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/tension-devices"
                      onClick={() => setOpenNav(false)}
                    >
                      Tension Devices
                    </Link>
                  </li>
                  <li>
                    <Link to="/ancillarys" onClick={() => setOpenNav(false)}>
                      Ancillary
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/parts-accessories"
                      onClick={() => setOpenNav(false)}
                    >
                      Parts and Accessories
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" onClick={() => setOpenNav(false)}>
                      Service
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/unrolling-creels">
                  Composites{" "}
                  <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                </Link>
                <ul className="inner-links">
                  <li>
                    <Link
                      to="/unrolling-creels"
                      onClick={() => setOpenNav(false)}
                    >
                      unrolling creels
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/composites/driven-systems"
                      onClick={() => setOpenNav(false)}
                    >
                      Driven systems
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/composites/custom-solutions"
                      onClick={() => setOpenNav(false)}
                    >
                      custom solutions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/parts-accessories"
                      onClick={() => setOpenNav(false)}
                    >
                      parts & accessories
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" onClick={() => setOpenNav(false)}>
                      Service
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/news" onClick={() => setOpenNav(false)}>
                  News
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  Contact <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                </Link>
                <ul className="inner-links">
                  <li>
                    <Link to="/contact" onClick={() => setOpenNav(false)}>
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact/request-a-quotes"
                      onClick={() => setOpenNav(false)}
                    >
                      Sales
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" onClick={() => setOpenNav(false)}>
                      Repair / service
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/parts-accessories"
                      onClick={() => setOpenNav(false)}
                    >
                      Parts
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* Mobile menu ============================================================== */}
        <div
          className={`container-box py-2 navibar ${
            stickyNavbar ? "white-navbar" : ""
          }`}
          id="rejveer-mobile"
        >
          <div className="dispalay-n d-xs-block">
            <div className="d-flex">
              <NavLink to="">
                <img
                  src={logo}
                  alt="logo"
                  className="mobile-logo"
                  loading="lazy"
                />
              </NavLink>
              <button
                className="navbar-toggler mt-3 ms-2 mb-3 mobile-btn"
                onClick={() => setMobileMenu(true)}
              >
                <span className="navbar-toggler-icon text-dark fs-2"> ☰</span>
              </button>
            </div>
          </div>
          <div className={`mobile-menu ${mobileMenu && "showMenu"}`}>
            <ul className="mobile-navlinks ps-0 pt-2">
              <div className="d-flex justify-content-end mb-5">
                <h2
                  onClick={() => setMobileMenu(false)}
                  className="menu-btn text-center px-3 py-2 me-2 mt-2 rounded-circle text-white"
                  style={{ backgroundColor: "#ffffff17", cursor: "pointer" }}
                >
                  &times;
                </h2>
              </div>
              <li>
                <NavLink onClick={() => setMobileMenu(false)} to="">
                  Home
                </NavLink>
              </li>
              <InnerLinks
                setMobileMenu={setMobileMenu}
                linkData={aboutLinks}
                name="About Us"
              />
              <InnerLinks
                setMobileMenu={setMobileMenu}
                linkData={textileLinks}
                name="Textiles"
              />
              <InnerLinks
                setMobileMenu={setMobileMenu}
                linkData={compositeLinks}
                name="Composites"
              />

              <li>
                <NavLink onClick={() => setMobileMenu(false)} to="news">
                  News
                </NavLink>
              </li>
              <InnerLinks
                setMobileMenu={setMobileMenu}
                linkData={contactLinks}
                name="Contact"
              />
            </ul>
          </div>
        </div>
      </nav>
      <div
        className={`top-arrow-div shadow ${upArrow ? "show-up-arrow" : ""}`}
        onClick={() => window.scrollTo(0, 0)}
      >
        <FontAwesomeIcon
          icon={faArrowUp}
          className="top-arrow"
        ></FontAwesomeIcon>
      </div>
    </header>
  );
};

export default Navbar;
