import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

import Spinner from "../../HomePage/Spinner";
import axios from "axios";
const CustomSolutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  document.title = "Custom Solutions | McCoy Machinery Company, Inc.";

  const [isLoading, setLoading] = useState(true);
  const [ctaSection, setCtaSection] = useState([]);
  const [customSolution, setCustomSolution] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/about"
        );
        setCtaSection(data.cta_section);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/composites"
        );
        setCustomSolution(data.custom_solutions);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <section className="header-section">
        <div className=" container-box">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="header-heading">
                <h2>Custom Solutions</h2>
                <p>
                  <Link to="/" aria-label="Home Page Link">
                    Home
                  </Link>
                  <FontAwesomeIcon
                    icon={faArrowRightLong}
                    className="px-3"
                  ></FontAwesomeIcon>
                  Custom Solutions
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light">
        <div className="container-box pb-5 pt-4">
          {customSolution.map((item, index) => {
            return (
              <div className="row justify-content-between">
                <div className="col-md-6">
                  <h2 className="text-dark-blue h2 fw-bold">
                    {item.title}
                  </h2>
                  <p className="paragraph mt-4">
                  <div
                        dangerouslySetInnerHTML={{
                          __html: item.overview,
                        }}
                      />
                  </p>
                </div>
                <div className="col-md-5 mt-md-5 pt-md-4 pt-5">
                  <div className="position-relative">
                    <img
                      src={`https://mccoy-usa.com/admin/assets/front/img/courses/${item.textile_image}`}
                      className="custom-img"
                      alt="about-image"
                    />
                    <img
                      src="https://mccoy-usa.com/admin/assets/lfm/files/1/Composites/custom-solutions1.69c9fcb7ef2fd7bbc15c.jpg"
                      className="solution-img"
                      alt="about-overlay-image"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="py-5 section-2">
        <div className="container-box py-4 row align-items-center">
          {ctaSection.map((item, index) => {
            return (
              <div className="col-md-7 text-white" key={index}>
                <h2 className="h2">{item.cta_title}</h2>
                <h5 className="h5 py-2">{item.cta_subtitle}</h5>
                <p className="paragraph mt-3">{item.cta_overview}</p>
                <Link
                  to="/contact/request-a-quotes"
                  className="carousel-content-btn bg-gold d-inline-block mt-4"
                >
                  {" "}
                  Request a quote
                </Link>

                <Link
                  to="/contact"
                  className="carousel-content-btn d-inline-block mt-4"
                >
                  {" "}
                  contact us
                </Link>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default CustomSolutions;
